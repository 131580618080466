<template>
  <div>
    <CCard>
      <CCardBody>
        <CSelect :label="$t('FORM_CHILDPOOL_COMPONENT_LABEL_TYPE')" horizontal :options="options" :value.sync="type" />
        <template v-if="isEdit">
          <CRow class="input">
            <CCol sm="3">
              <h6>{{$t("FORM_CHILDPOOL_COMPONENT_LABEL_PARTNER_ID")}}</h6>
            </CCol>
            <CCol sm="9">
              <h6>
                <strong>{{this.id}}</strong>
              </h6>
            </CCol>
          </CRow>
        </template>
        <ValidationObserver ref="observer" @submit.prevent="save">
          <ValidationProvider v-slot="{ errors }" :name="$t('FORM_CHILDPOOL_COMPONENT_FIELD_NAME_VALIDATE_NAME')" rules="required">
            <CInput class="input" :label="$t('FORM_CHILDPOOL_COMPONENT_LABEL_PARTNER_NAME')" :placeholder="$t('FORM_CHILDPOOL_COMPONENT_PLACEHOLDER_PARTNER_NAME')" horizontal v-model="name" />
            <CRow>
              <CCol :sm="{offset:'3'}"></CCol>
              <CCol :sm="9">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>

          <ValidationProvider
            v-if="!isEdit"
            v-slot="{ errors }"
            :name="$t('FORM_CHILDPOOL_COMPONENT_FIELD_NAME_VALIDATE_EMAIL')"
            rules="required|email"
          >
            <CInput class="input" :label="$t('FORM_CHILDPOOL_COMPONENT_LABEL_EMAIL')" :placeholder="$t('FORM_CHILDPOOL_COMPONENT_PLACEHOLDER_EMAIL')" horizontal v-model="email" />
            <CRow>
              <CCol :sm="{offset:'3'}"></CCol>
              <CCol :sm="9">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </ValidationObserver>

        <template v-if="isEdit">
          <CRow class="input">
            <CCol sm="3">
              <h6>{{$t("FORM_CHILDPOOL_COMPONENT_LABEL_EMAIL")}}</h6>
            </CCol>
            <CCol sm="9">
              <h6>
                <strong>{{this.email}}</strong>
              </h6>
            </CCol>
          </CRow>
        </template>

        <CButton
          block
          color="primary"
          class="button-save"
          @click="save"
        >{{partnerId?$t("FORM_CHILDPOOL_COMPONENT_BUTTON_SAVE"):$t("FORM_CHILDPOOL_COMPONENT_BUTTON_CREATE")}}</CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    handler: Function,
    partnerId: String,
    partner: Object
  },

  data() {
    return {
      options: [
        {
          label: this.$t('FORM_CHILDPOOL_COMPONENT_OPTION_SELECT_CHILD'),
          value: "CHILD"
        },
        {
          label: this.$t('FORM_CHILDPOOL_COMPONENT_OPTION_SELECT_AFFILIATE'),
          value: "AFFILIATE"
        }
      ],
      name: "",
      type: "AFFILIATE",
      email: "",
      isEdit: false
    };
  },

  mounted() {
    let checkId = this.$route.params.id;
    if (checkId) {
      this.id = this.partner.id;
      this.name = this.partner.name;
      this.type = this.partner.partner_type;
      this.email = this.partner.email;
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
  },

  methods: {
    save() {
      this.$refs.observer.validate().then(isValid => {
        if (!isValid) {
          return;
        } else {
          this.handler(this.name, this.type, this.email);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.button-save {
  width: 120px;
  float: right;
  margin-top: 5px;
}

.input {
  margin-top: 1em;
  margin-bottom: 5px;
}

.error-msg {
  color: red;
  padding-left: 0;
}
</style>
