<template>
  <div class="childpool-wrap">
    <CRow>
      <CCol md="12" lg="7">
        <Form :handler="(partnerId)?update:create" :partnerId="partnerId" :partner="partner" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Form from "@/components/ChildPool/Form";
import endpoints from "@/constants/endpoints";
import { mapState, mapActions } from "vuex";
export default {
  name: "EditPool",
  components: {
    Form
  },
  data() {
    return {
      partnerId: ""
    };
  },
  computed: {
    ...mapState({
      partner: state => state.partner.partnerSelected
    })
  },
  mounted() {
    this.partnerId = this.$route.params.id;
  },

  async beforeRouteEnter(to, from, next) {
    let id = to.params.id;
    if (!id) {
      next();
    } else {
      next(vm => {
        if (vm.$store.state.partner.partnerSelected.id !== id) {
          vm.$router.push("/admin/childpool");
        } else {
          return;
        }
      });
    }
  },

  methods: {
    ...mapActions(["updatePartner", "createPartner"]),

    async update(name, type) {
      let updatePartner = {
        id: this.partnerId,
        name: name,
        partner_type: type,
      };
      await this.updatePartner(updatePartner);
    },

    async create(name, type, email) {
      let newPartner = {
        name: name,
        partner_type: type,
        email: email
      };
      await this.createPartner(newPartner);
    }
  }
};
</script>
<style lang="scss">
  .childpool-wrap{
    .card{
      .card-body{
        label{
          width: 25%;
          text-align: right;
          padding-right: 15px;
          line-height: 25px;
          color: #3E4B65 !important;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.34px;
          margin-bottom: 0px;
          white-space: nowrap;
          @media only screen and ( max-width: 576px ){
            width: 100%;
            text-align: left;
          }
        }
        .button-save{
          min-width: 146px;
          height: 36px;
          border-radius: 4px;
          background-color: #3B4B7E;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;
          border: none;
          outline: none !important;
          -webkit-box-shadow: none;
          box-shadow: none;
          text-align: center;
        }
        h6{
          margin-right: -5px;
          text-align: right;
        }
        div{
          h6{
            strong{
              margin-left: -5px;
              float: left;
            }
          }
        }
      }
    }
  }
</style>
